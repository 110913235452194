<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                <span class="font-weight-medium d-block grey--text text--darken-3 fs-20" v-else>
                  {{ statistics.total_campaigns }}
                </span>
                <p class="mt-2 mb-0">Total campagnes</p>
              </div>
              <v-spacer/>
              <v-avatar class="primary" size="45">
                <v-icon dark size="25">mdi-file-document-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                <span class="font-weight-medium d-block grey--text text--darken-3 fs-20" v-else>
                  {{ statistics.total_sales }}
                </span>
                <p class="mt-2 mb-0">Total ventes</p>
              </div>
              <v-spacer/>
              <v-avatar class="secondary" size="45">
                <v-icon dark size="25">mdi-shopping-outline</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-6">
            <div class="d-flex align-center justify-space-between">
              <div>
                <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                <span class="font-weight-medium d-block grey--text text--darken-3 fs-20" v-else>
                  {{ $func.formatPrice(statistics.total_flexy) }} DZD
                </span>

                <div class="mt-2 mb-0">
                  Total flexy

                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip small class="bg-indigo-subtle font-weight-medium ml-3 indigo--text"
                              v-bind="attrs"
                              v-on="on">
                        <v-icon left small color="indigo">mdi-calendar</v-icon>
                        Filtrer
                        <v-icon right small color="indigo">mdi-chevron-down</v-icon>
                      </v-chip>
                    </template>
                    <v-date-picker range v-model="dates" scrollable color="indigo">
                      <v-spacer></v-spacer>
                      <v-btn depressed color="primary" :disabled="(dates.length < 2)"
                             @click="[getStatistics(),menu = false]">
                        Valider
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-btn class="bg-red-subtle ml-1 red--text"
                         icon
                         v-if="dates.length === 2"
                         @click="[dates = [],getStatistics()]">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>

                </div>

              </div>
              <v-spacer/>
              <v-avatar class="indigo" size="45">
                <v-icon dark size="25">mdi-currency-eur</v-icon>
              </v-avatar>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      menu: false,
      isLoading: false,
      dates: [],
      statistics: {},
    }
  },
  methods: {
    getStatistics() {
      this.isLoading = true
      HTTP.get('/v2-campaigns/statistics', {
        params: {
          start_date: this.dates.length === 2 ? this.dates[0] : null,
          end_date: this.dates.length === 2 ? this.dates[1] : null
        }
      }).then((res) => {
        this.isLoading = false
        this.statistics = res.data.data
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  created() {
    this.getStatistics()
  }
}
</script>

<style scoped>

</style>