<template>
  <div>
    <v-dialog v-if="data"
              v-model="dialog"
              persistent
              scrollable
              width="400">
      <v-card :disabled="btnLoading" :loading="btnLoading">

        <v-card-title>
          Prolonger date de fin
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">

          <v-menu ref="menu"
                  v-model="startDateMenu"
                  :close-on-click="true"
                  min-width="auto"
                  offset-y
                  transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :disabled="data.remaining_days.state !== 'waiting'"
                            :error-messages="errors.start_date"
                            :value="form.start_date"
                            append-icon="mdi-calendar"
                            dense
                            label="Date de début *"
                            outlined
                            readonly v-bind="attrs"
                            @input="errors && errors.start_date ? errors.start_date = '' : false" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker
                v-model="form.start_date"
                :min="dateNow" scrollable>
            </v-date-picker>
          </v-menu>

          <v-menu ref="menu"
                  v-model="endDateMenu"
                  :close-on-click="true"
                  min-width="auto"
                  offset-y
                  transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field :error-messages="errors.end_date"
                            :value="form.end_date"
                            append-icon="mdi-calendar"
                            hide-details="auto"
                            dense
                            label="Date de fin *"
                            outlined
                            readonly v-bind="attrs"
                            @input="errors && errors.end_date ? errors.end_date = '' : false" v-on="on">
              </v-text-field>
            </template>
            <v-date-picker
                v-model="form.end_date"
                :min="data.remaining_days.state === 'completed' ? dateNow : form.start_date" scrollable>
            </v-date-picker>
          </v-menu>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn :loading="btnLoading"
                 color="secondary"
                 depressed
                 @click="save">
            <v-icon left>mdi-content-save</v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import moment from "moment";

export default {
  data() {
    return {
      dateNow: moment().format('YYYY-MM-DD'),
      btnLoading: false,
      dialog: false,
      startDateMenu: false,
      endDateMenu: false,
      form: {
        campaign_id: null,
        start_date: null,
        end_date: null,
      },
      errors: {},
      data: null,
    }
  },
  methods: {
    open(item) {
      this.data = item
      this.form.campaign_id = item.id
      this.form.start_date = item.start_date
      this.form.end_date = item.end_date
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.btnLoading = true
      HTTP.post('/v2-campaigns/prolong-end-date', this.form).then(() => {
        this.btnLoading = false
        this.close()
        this.$emit('refresh')
        this.$successMessage = 'Cette campagne a été modifier avec succès'
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    }
  },
}
</script>

<style scoped>

</style>