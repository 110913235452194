import { render, staticRenderFns } from "./DetailDialog.vue?vue&type=template&id=36b3c696&scoped=true&"
import script from "./DetailDialog.vue?vue&type=script&lang=js&"
export * from "./DetailDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b3c696",
  null
  
)

export default component.exports