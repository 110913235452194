<template>
  <div>
    <v-dialog v-model="dialog" fullscreen persistent>

      <v-card>

        <v-card-title>
          Ventes
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>


        <v-card-text class="pa-10">

          <div class="text-end mb-3">
            <v-btn color="indigo" icon @click="getSales">
              <v-icon>mdi-table-refresh</v-icon>
            </v-btn>
          </div>

          <v-skeleton-loader v-if="isLoading" type="table"/>

          <div v-else>

            <div v-if="sales.length">
              <v-simple-table class="table-border rounded-lg">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-no-wrap">ID</th>
                    <th class="text-no-wrap">Animateur</th>
                    <th class="text-no-wrap">POS</th>
                    <th class="text-no-wrap">Nom du consommateur</th>
                    <th class="text-no-wrap">Tél du consommateur</th>
                    <th class="text-no-wrap">Âge</th>
                    <th class="text-no-wrap">Produit consomé</th>
                    <th class="text-no-wrap">Produit acheté</th>
                    <th class="text-no-wrap">Type de vente</th>
                    <th class="text-no-wrap">Cadeau</th>
                    <th class="text-no-wrap">Date</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,index) in sales" :key="index">

                    <td>
                      {{ item.id }}
                    </td>

                    <td class="indigo--text text-no-wrap">
                      {{ item.animator ? item.animator.name : "-" }}
                    </td>

                    <td>
                      <v-list-item v-if="item.salepoint" class="pl-0 ml-0" dense>

                        <v-list-item-avatar v-if="item.salepoint.image" left size="35">
                          <v-img :src="$baseUrl + item.salepoint.image"
                                 class="zoom-pointer"
                                 size="35"
                                 @click="$refs.lightbox.open(item.salepoint.image)"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-avatar v-else
                                            :class="$func.randomColor()"
                                            class="font-weight-medium"
                                            left
                                            size="35"
                        >
                          {{ $func.avatarName(item.salepoint.name) }}
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-text="item.salepoint.name"></v-list-item-title>
                          <v-list-item-subtitle class="indigo--text"
                                                v-text="item.salepoint.code"></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <div v-else>-</div>
                    </td>

                    <td>
                      {{ item.customer_name }}
                    </td>

                    <td>
                      {{ item.customer_phone }}
                    </td>

                    <td>
                      {{ item.customer_age }}
                    </td>

                    <td class="text-no-wrap">
                      {{ item.original_product ? item.original_product.name : "-" }}
                    </td>

                    <td class="text-no-wrap">
                      {{ item.campaign_product ? item.campaign_product.name : "-" }}
                    </td>

                    <td class="text-no-wrap">
                      <v-chip small v-if="item.sale_type === 'refusal'"
                              text-color="red"
                              class="bg-red-subtle font-weight-medium">
                        Refus
                      </v-chip>
                      <v-chip small v-if="item.sale_type === 'switch'"
                              text-color="blue"
                              class="bg-blue-subtle font-weight-medium">
                        Switch
                      </v-chip>
                      <v-chip small v-if="item.sale_type === 'fidelity'"
                              text-color="success"
                              class="bg-success-subtle font-weight-medium">
                        Fidélité
                      </v-chip>
                    </td>

                    <td class="font-weight-medium primary--text text-no-wrap">
                      <v-chip v-if="item.gift" color="primary" dark small>
                        {{ item.gift.name }}
                      </v-chip>
                      <v-icon v-else color="red">mdi-close-circle</v-icon>
                    </td>

                    <td class="text-no-wrap indigo--text">
                      <v-icon color="indigo" small>mdi-clock</v-icon>
                      {{ $func.dateFormat(item.date) }} -
                      {{ $func.hourFormat(item.start_time) }} à
                      {{ $func.hourFormat(item.end_time) }}
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-card-actions v-if="total > 10">

                <div>
                  <v-select v-model="per_page"
                            :items="[10,20,50,100,200,300,400,500]"
                            :style="{width: '120px'}"
                            dense
                            hide-details
                            label="Ligne par page"
                            outlined></v-select>
                </div>

                <v-spacer/>
                <v-pagination v-if="total>10"
                              v-model="pagination.current"
                              :length="pagination.total"
                              total-visible="6"
                              @input="onPageChange"
                ></v-pagination>
              </v-card-actions>
            </div>

            <NoResult v-else/>
          </div>

        </v-card-text>

      </v-card>


    </v-dialog>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      dialog: false,
      sales: [],
      campaign_id: null,
      total: 0,
      per_page: 10,
      pagination: {
        current: 1,
        total: 0
      },
    }
  },
  methods: {
    onPageChange() {
      this.getSales();
    },
    open(campaign_id) {
      this.campaign_id = campaign_id
      this.dialog = true
      this.getSales()
    },
    getSales() {
      this.isLoading = true
      HTTP.get('/v2-campaigns/' + this.campaign_id + '/all-sales?page=' + this.pagination.current, {
        params: {
          per_page: this.per_page,
        }
      }).then((res) => {
        this.isLoading = false
        this.sales = res.data.data.data

        this.pagination.current = res.data.data.current_page
        this.pagination.total = res.data.data.last_page
        this.total = res.data.data.total

      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
  },
  watch: {
    per_page() {
      this.pagination.current = 1
      this.getSales();
    },
  },
}
</script>

<style scoped>

</style>